<template>
  <div :class="['DataBoard', !options.title? 'empty' : '']">
    <div class="DataBoard-title">
      <div class="main">{{ options.title }}</div>
      <div class="sub">{{ options.subTitle || '' }}</div>
    </div>
    <div class="DataBoard-value">
      <span class="value" v-if="options.title">{{ formatNum(options.value, (options.digit)) }}</span>
      <span v-if="options.unit" class="unit"> {{ options.unit }}</span>
    </div>
    <div v-if="options.icon" :class="['DataBoard-chart', options.icon]"></div>
  </div>
</template>

<script>
  import { formatNum } from '@/utils';
  export default {
    name: 'DataBoard',
    props: {
      options: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        formatNum
      }
    },
  }
</script>

<style lang="scss" scoped>
.DataBoard{
  padding: 24px 24px;
  background-color: #fff;
  &.empty{
    background-color: transparent;
  }
  &-title{
    display: flex;
    justify-content: space-between;
    .main,
    .sub{
      color: rgba(0,0,0,0.45);
    }

  }
  &-value{
    margin: 15px 0;
    .value{
      font-size: 30px;
      color: rgba(0,0,0,0.85);
    }
    .unit{
      color: rgba(0,0,0,0.55);
    }
  }
  &-chart{
    height: 25px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &.line{
      background-image: url('~@/assets/images/dashboard/chart-img1.png');
    }
    &.bar{
      background-image: url('~@/assets/images/dashboard/chart-img2.png');
    }
  }
}
</style>