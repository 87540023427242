<template>
  <div class="ReportVolumeAnalysis">
    <div class="ReportVolumeAnalysis-item">
      <div class="statistics_title">业务类型申报量-<span class="sub_title">单量分析</span></div>
      <div class="ReportVolumeAnalysis-item-container">
        <DataBoard
          :options="options9610"
        />
        <DataBoard
          :options="options9710"
        />
        <DataBoard
          :options="options9810"
        />
        <DataBoard
          :options="options1210"
        />
      </div>
    </div> 
    <div class="ReportVolumeAnalysis-item" style="margin-top: 24px;">
      <div class="statistics_title">业务类型申报量-<span class="sub_title">占比分析</span></div>
      <div class="ReportVolumeAnalysis-item-container">
        <DataBoard
          :options="optionsRatio9610"
        />
        <DataBoard
          :options="optionsRatio9710"
        />
        <DataBoard
          :options="optionsRatio9810"
        />
        <DataBoard
          :options="optionsRatio1210"
        />
      </div>
    </div> 
    <!-- <div class="statistics-container">
      <div class="statistics-container-item">
        <div class="statistics_title">业务类型申报量-<span class="sub_title">单量分析</span></div>
        <BarChart
          :optionsData="barOptions"
        />
      </div>
      <div class="statistics-container-item">
        <div class="statistics_title">业务类型申报量-<span class="sub_title">占比分析</span></div>
        <PieChart
          :optionsData="pieOptions"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
// import BarChart from '../BarChart.vue';
// import PieChart from '../PieChart.vue';
import { mapState } from 'vuex';

import DataBoard from '../DataBoard.vue';

export default {
    name: 'ReportVolumeAnalysis',
    components: {
      DataBoard,
      // BarChart,
      // PieChart,
    },
    props: {
      queryParams: {
        type: Object,
        required: true, 
      },
    },
    data() {
      return {
        options9610: {
          title: '申报单量（9610）',
          value: 0,
          digit: 0,
        },
        options9710: {
          title: '申报单量（9710）',
          value: 0,
          digit: 0,
        },
        options9810: {
          title: '申报单量（9810）',
          value: 0,
          digit: 0,
        },
        options1210: {
          title: '申报单量（1210）',
          value: 0,
          digit: 0,
        },
        optionsRatio9610: {
          title: '申报占比（9610）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        optionsRatio9710: {
          title: '申报占比（9710）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        optionsRatio9810: {
          title: '申报占比（9810）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        optionsRatio1210: {
          title: '申报占比（1210）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        barOptions: {
          xData: [],
          axisValueName: "单",
          seriesData: [],
        },
        pieOptions: {
          legend: [],
          axisValueName: "单",
          seriesData: [],
        }
      }
    },
    computed: {
      ...mapState([
        'serviceModeEnums'
      ])
    },
    watch: {
      queryParams: {
        // immediate: true,
        handler(params) {
          this.setReset();
          this.getData(params);
          this.getRatioData(params);
        }
      }
    },
    mounted () {
      // this.getData();
    },
    methods: {
      async getData(params) {
        const res = await this.$request.get('/data/businessTypeData/declareTotalNum', { params });
        if(res.data && res.data.length) {
          // const colors = {
          //   9610: '#3484FB',
          //   9710: '#23B899',
          //   9810: '#8978FF',
          //   1210: '#FE7C4B',
          // };
          res.data.forEach((item) => {
            const { tradeMode, num } = item;
            this[`options${tradeMode}`] && (this[`options${tradeMode}`].value = num);
          });
        }
      },
      async getRatioData(params) {
        const res = await this.$request.get('/data/businessTypeData/ratio', { params });
        if(res.data && res.data.length) {
          res.data.forEach((item) => {
            const { percent, tradeMode } = item;
            this[`optionsRatio${tradeMode}`] && (this[`optionsRatio${tradeMode}`].value = percent);
          });
        }
      },
      setReset() {
        this.options9610 = {
          title: '申报单量（9610）',
          value: 0,
          digit: 0,
        }
        this.options9710 = {
          title: '申报单量（9710）',
          value: 0,
          digit: 0,
        }
        this.options9810 = {
          title: '申报单量（9810）',
          value: 0,
          digit: 0,
        }
        this.options1210 = {
          title: '申报单量（1210）',
          value: 0,
          digit: 0,
        }
        this.optionsRatio9610 = {
          title: '申报占比（9610）',
          value: 0,
          digit: 0,
          unit: '%',
        }
        this.optionsRatio9710 = {
          title: '申报占比（9710）',
          value: 0,
          digit: 0,
          unit: '%',
        }
        this.optionsRatio9810 = {
          title: '申报占比（9810）',
          value: 0,
          digit: 0,
          unit: '%',
        }
        this.optionsRatio1210 = {
          title: '申报占比（1210）',
          value: 0,
          digit: 0,
          unit: '%',
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.ReportVolumeAnalysis{
  &-item{
    // padding: 24px 24px;
    // background-color: #fff;
    .statistics_title{
      // padding: 24px 24px;
      margin-bottom: 24px;
      // background-color: #fff;
    }
    &-container{
      display: flex;
      justify-content: space-between;
      background-color: #F5F7F8;
      .DataBoard{
        flex: 1;
        margin-left: 24px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }
}
</style>