<template>
  <div class="dashboard-container Bussiness">
    <SearchQuery :queryParams.sync="queryParams" />
    <div class="dashboard-container-main Bussiness-container">
      <ReportVolumeAnalysis :queryParams.sync="queryParams" />
      <YoY :queryParams.sync="queryParams" />
      <Currency :queryParams.sync="queryParams" />
      <SyntheticTable
        :columes="columes"
        requestUrl="/businessTypeData/overallQuery"
      />
    </div>
  </div>
</template>

<script>
  import SearchQuery from '../SearchQuery.vue';
  import ReportVolumeAnalysis from './ReportVolumeAnalysis.vue';
  import Currency from './Currency.vue';
  import SyntheticTable from '../SyntheticTable.vue';
  import YoY from './YoY.vue';
  export default {
    name: 'Bussiness',
    components: {
      SearchQuery,
      ReportVolumeAnalysis,
      YoY,
      Currency,
      SyntheticTable,
    },
    data() {
      return {
        queryParams: {
          timeTag: '',
          startDate: '',
          endDate: '',
        },
        columes: [
          { prop: 'tradeMode', label: '业务类型' },
          { prop: 'num', label: '单量' },
          { prop: 'RMB', label: '人民币（万元）' },
          { prop: 'USD', label: '美元（万元）' },
          { prop: 'YEN', label: '日元（万元）' },
        ],
      }
    },
    methods: {
      handlePaginationChange(pagination) {
        const { size, current } = pagination;
        this.pagination = {
          size,
          current
        }
        this.getData();
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>