<template>
  <div class="YoY">
    <div class="YoY-item">
      <div class="statistics_title">业务类型申报量-<span class="sub_title">同比分析</span></div>
      <div class="YoY-item-container">
        <DataBoard
          :options="optionsYOY9610"
        />
        <DataBoard
          :options="optionsYOY9710"
        />
        <DataBoard
          :options="optionsYOY9810"
        />
        <DataBoard
          :options="optionsYOY1210"
        />
      </div>
    </div> 
    <div class="YoY-item" style="margin-top: 24px;">
      <div class="statistics_title">业务类型申报量-<span class="sub_title">环比分析</span></div>
      <div class="YoY-item-container">
        <DataBoard
          :options="optionsLYR9610"
        />
        <DataBoard
          :options="optionsLYR9710"
        />
        <DataBoard
          :options="optionsLYR9810"
        />
        <DataBoard
          :options="optionsLYR1210"
        />
      </div>
    </div> 
    <!-- <div class="statistics-container">
      <div class="statistics-container-item">
        <div class="statistics_title">业务类型申报量-<span class="sub_title">同比分析</span></div>
        <LineChart
          type="line"
          :optionsData="barTBOptions"
        />
      </div>
      <div class="statistics-container-item">
        <div class="statistics_title">业务类型申报量-<span class="sub_title">环比分析</span></div>
        <LineChart
          type="line"
          :optionsData="barHBOptions"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DataBoard from '../DataBoard.vue';

export default {
    name: 'YoY',
    components: {
      DataBoard,
    },
    props: {
      queryParams: {
        type: Object,
        required: true, 
      },
    },
    data() {
      return {
        optionsYOY9610: {
          title: '同比（9610）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        optionsYOY9710: {
          title: '同比（9710）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        optionsYOY9810: {
          title: '同比（9810）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        optionsYOY1210: {
          title: '同比（1210）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        optionsLYR9610: {
          title: '环比（9610）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        optionsLYR9710: {
          title: '环比（9710）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        optionsLYR9810: {
          title: '环比（9810）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        optionsLYR1210: {
          title: '环比（1210）',
          value: 0,
          digit: 0,
          unit: '%',
        },
        barHBOptions: {
          xData: [],
          axisValueName: "%",
          seriesData: [],
        }
      }
    },
    computed: {
      ...mapState([
        'serviceModeEnums'
      ])
    },
    watch: {
      queryParams: {
        handler(params) {
          this.setReset();
          this.getData(params);
        }
      }
    },
    mounted () {
    },
    methods: {
      async getData(params) {
        const resTb = await this.$request.get('/data/businessTypeData/yoy', { params });
        if(resTb.data && resTb.data.length) {
          resTb.data.forEach((item) => {
            const { tradeMode, ratio } = item;
            this[`optionsYOY${tradeMode}`] && (this[`optionsYOY${tradeMode}`].value = ratio);
          });
        }
        const resHb = await this.$request.get('/data/businessTypeData/mom', { params });
        if(resHb.data && resHb.data.length) {
          resHb.data.forEach((item) => {
            const { tradeMode, ratio } = item;
            this[`optionsLYR${tradeMode}`] && (this[`optionsLYR${tradeMode}`].value = ratio);
          });
        }
      },
      setReset() {
        this.optionsYOY9610 = {
          title: '同比（9610）',
          value: 0,
          digit: 0,
          unit: '%',
        }
        this.optionsYOY9710 = {
          title: '同比（9710）',
          value: 0,
          digit: 0,
          unit: '%',
        }
        this.optionsYOY9810 = {
          title: '同比（9810）',
          value: 0,
          digit: 0,
          unit: '%',
        }
        this.optionsYOY1210 = {
          title: '同比（1210）',
          value: 0,
          digit: 0,
          unit: '%',
        }
        this.optionsLYR9610 = {
          title: '环比（9610）',
          value: 0,
          digit: 0,
          unit: '%',
        }
        this.optionsLYR9710 = {
          title: '环比（9710）',
          value: 0,
          digit: 0,
          unit: '%',
        }
        this.optionsLYR9810 = {
          title: '环比（9810）',
          value: 0,
          digit: 0,
          unit: '%',
        }
        this.optionsLYR1210 = {
          title: '环比（1210）',
          value: 0,
          digit: 0,
          unit: '%',
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .YoY{
    margin: 24px 0;
    &-item{
      // padding: 24px 24px;
      // background-color: #fff;
      .statistics_title{
        margin-bottom: 24px;
      }
      &-container{
        display: flex;
        justify-content: space-between;
        background-color: #F5F7F8;
        .DataBoard{
          flex: 1;
          margin-left: 24px;
          &:first-child{
            margin-left: 0;
          }
        }
      }
    }
  }
</style>