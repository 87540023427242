<template>
  <div class="Currency">
    <template v-for="(option, type) in options">
      <div :key="type" class="Currency-item">
        <div class="statistics_title">业务类型申报金额分析-<span class="sub_title">{{ type }}</span></div>
        <div class="Currency-item-container">
          <DataBoard
            :options="option.rmbOptions"
          />
          <DataBoard
            :options="option.usdOptions"
          />
          <DataBoard
            :options="option.jpyOptions"
          />
          <DataBoard
            :options="option.emptyOptions"
          />
        </div>
      </div> 
    </template>
    <!-- <div class="statistics_title">业务类型申报量分析</div>
    <el-row :gutter="40">
      <el-col :span="8">
        <div class="chart-title">币种：<span>人民币</span></div>
        <BarChart
          :optionsData="RMBOptions"
        />
      </el-col>
      <el-col :span="8">
        <div class="chart-title">币种：<span>美元</span></div>
        <BarChart
          :optionsData="USDOptions"
        />
      </el-col>
      <el-col :span="8">
        <div class="chart-title">币种：<span>日元</span></div>
        <BarChart
          :optionsData="YENOptions"
        />
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
  import DataBoard from '../DataBoard.vue';
  import { mapState } from 'vuex';
  export default {
    name: 'Currency',
    components: {
      DataBoard,
    },
    props: {
      queryParams: {
        type: Object,
        required: true, 
      },
    },
    data() {
      return {
        options: {
          9610: {
            rmbOptions: {
              title: '人民币',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            usdOptions: {
              title: '美元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            jpyOptions: {
              title: '日元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            emptyOptions: {
              title: '',
              value: '',
              digit: 0,
            }
          },
          9710: {
            rmbOptions: {
              title: '人民币',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            usdOptions: {
              title: '美元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            jpyOptions: {
              title: '日元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            emptyOptions: {
              title: '',
              value: '',
              digit: 3,
            }
          },
          9810: {
            rmbOptions: {
              title: '人民币',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            usdOptions: {
              title: '美元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            jpyOptions: {
              title: '日元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            emptyOptions: {
              title: '',
              value: '',
              digit: 3,
            }
          },
          1210: {
            rmbOptions: {
              title: '人民币',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            usdOptions: {
              title: '美元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            jpyOptions: {
              title: '日元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            emptyOptions: {
              title: '',
              value: '',
              digit: 3,
            }
          }
        },
        RMBOptions: {
          xData: [],
          axisValueName: "万元",
          seriesData: [],
        },
        USDOptions: {
          xData: [],
          axisValueName: "万元",
          seriesData: [],
        },
        YENOptions: {
          xData: [],
          axisValueName: "万元",
          seriesData: [],
        },
      }
    },
    computed: {
      ...mapState([
        'serviceModeEnums'
      ])
    },
    watch: {
      queryParams: {
        handler(params) {
          this.setReset();
          this.getData(params);
        }
      }
    },
    mounted () {
    },
    methods: {
      async getData(params) {
        const res = await this.$request.get('/data/businessTypeData/tradeAmount', { params });
        if(res.data) {
          Object.keys(res.data).forEach((tradeMode) => {
            res.data[tradeMode].forEach(item => {
              const { currency, tradeMode, totalPrice } = item;
              switch(currency) {
                case '人民币':
                  this.options[tradeMode].rmbOptions.value = (totalPrice / 10000);
                  break;
                case '美元':
                  this.options[tradeMode].usdOptions.value = (totalPrice / 10000);
                  break;
                case '日本元':
                  this.options[tradeMode].jpyOptions.value = (totalPrice / 10000);
                  break;
              }
            })
          });
        }
      },
      setReset() {
        this.options = {
          9610: {
            rmbOptions: {
              title: '人民币',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            usdOptions: {
              title: '美元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            jpyOptions: {
              title: '日元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            emptyOptions: {
              title: '',
              value: '',
              digit: 3,
            }
          },
          9710: {
            rmbOptions: {
              title: '人民币',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            usdOptions: {
              title: '美元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            jpyOptions: {
              title: '日元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            emptyOptions: {
              title: '',
              value: '',
              digit: 3,
            }
          },
          9810: {
            rmbOptions: {
              title: '人民币',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            usdOptions: {
              title: '美元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            jpyOptions: {
              title: '日元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            emptyOptions: {
              title: '',
              value: '',
              digit: 3,
            }
          },
          1210: {
            rmbOptions: {
              title: '人民币',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            usdOptions: {
              title: '美元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            jpyOptions: {
              title: '日元',
              value: 0,
              digit: 3,
              unit: '万元'
            },
            emptyOptions: {
              title: '',
              value: '',
              digit: 3,
            }
          }
        };
      }
    },
  }
</script>

<style lang="scss" scoped>
.Currency{
  margin: 24px 0;
  &-item{
    // padding: 24px 24px;
    // background-color: #fff;
    .statistics_title{
      margin-top: 24px;
      margin-bottom: 24px;
    }
    &-container{
      display: flex;
      justify-content: space-between;
      background-color: #F5F7F8;
      .DataBoard{
        flex: 1;
        margin-left: 24px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }
  // height: 300px;
  // padding: 24px 24px;
  // background-color: #fff;
  // box-sizing: border-box;
  // .el-row{
  //   height: 100%;
  //   .el-col{
  //     height: 100%;
  //     .chart-title{
  //       padding-right: 20px;
  //       margin-top: 20px;
  //       color: #6C7293;
  //       text-align: right;
  //       span{
  //         color: #3484FB;
  //       }
  //     }
  //     .chart-container{
  //       height: calc(100% - 40px);
  //     }
  //   }
  // }
}
</style>